import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Scroll from "../components/scroll"
import TableOfContents from "./table-of-contents"
import "../styles/page.scss"

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        description
        featuredImage {
          childImageSharp {
            fixed(width: 1600) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      body
      tableOfContents
    }
  }
`

const PageTemplate = function ({ data: { mdx: page } }) {
  return (
    <Layout title={page.frontmatter.title}>
      <Seo
        title={page.frontmatter.title}
        myFeaturedImage={page.frontmatter.featuredImage}
        description = {page.frontmatter.description}
      />
      {page.frontmatter.slug === "faq" && (
        <TableOfContents items={page.tableOfContents.items} />
      )}
      <Scroll showBelow={600} />
      <MDXRenderer>{page.body}</MDXRenderer>
    </Layout>
  )
}
export default PageTemplate
